// Curatorship
import MISION_T from './curatorship/mision.translation'
import PQRS_T from './transparency/pqrs.translation'
import MAILOX_T from './transparency/mailbox.translation'
import LIQUIDATOR_T from './liquidator/liquidator.translation'
import SCHEDULING_T from './scheduling/scheduling.translation'
import { PQRS_TYPE_ID_ARRAY_EN } from '../../components/vars.global'

export const TRANSLATIONS_EN = {
    app_name: "curaduría",
    title: {
      str_title_1: "ARQ. LUIS CARLOS PARRA SALAZAR",
      str_title_2: "Bucaramanga N.1 Urban Curatorship",
    },
    nav_bar: {
      str_item_1: "Home",
      str_item_2: "Curatorship",
      str_item_2_1: "Mision and Vision",
      str_item_2_2: "The Curatorship",
      str_item_2_3: "What we do",
      str_item_2_4: "Organization chart",
      str_item_2_5: "Curatorship for Kids",
      str_item_2_6: "Inclusive Curatorship",
      str_item_2_7: "Online Certification",
      str_item_3: "Publications",
      str_item_3_1: "Publications",
      str_item_3_2: "Repository",
      str_item_3_3: "Nomenclature",
      str_item_3_4: "Normogram",
      str_item_3_5: "News",
      str_item_4: "Start licensing",
      str_item_4_1: "Forms",
      str_item_4_2: "Consult Results",
      str_item_4_3: "Check Status",
      str_item_5: "Contact",
      str_item_5_1: "File PQRS",
      str_item_5_2: "FAQ",
      str_item_5_3: "Curator's School",
      str_item_5_4: "Contact us",
      str_item_5_5: "Data and Figures",
      str_item_6: "Liquidator",
      str_item_7: "Scheduling",
    },
    footer: {
      str_title: "General Contact Information",
      str_cr: "© 2021 Bucaramanga Urban Curatorship Nr.1. Developed by",
      str_item_1: "Street 36 N° 31-39 Enterprise Center Chicamocha - Office 101 and Office 102",
      str_item_2: "680 3596",
      str_item_3: "316 279 5010",
      str_item_4: "curaduriaurbana1@gmail.com",
      str_item_5: "Moonday to Friday: 07:00 am - 12:30 pm and 1:00pm -5:00pm",
      str_p_1: "The Urban Curatorship Nr.1 of Bucaramanga offers the service of the Colombian sign language.",
      str_p_2: "Need help? Chat with us through WhatsApp",
      str_btn: "Chat online",
    },
    login: {
      str_title: "Bucaramanga 1st Curatorship Login Access",
      str_user: "Email Address",
      str_id: "Document Number",
      str_id2: "Certificate serial Number",
      str_pass: "Password",
      str_btn: "Log In",
      str_cert: "Option",
      str_btn2: "Generate Certificate",
      str_btn4: "Verify Certificate",
      str_btn3: "Send",
    },
    curatorship: {
      mision: MISION_T,
    },
    transparency: {
      pqrs_form: PQRS_T,
      mailbox: MAILOX_T,
    },
    liquidator: {
      liquidator: LIQUIDATOR_T,
    },
    scheduling: {
      scheduling: SCHEDULING_T,
    },
    swa_messages: {
      title_wait: '<h2>Processing Form</h2>',
      text_wait: 'Wait a moment please, this process could take up to a minute.',
      text_btn: 'CONTINUE',
      text_success: 'Form processed successfully',
      text_error: 'Form processing errors have occurred, please try again later.',
      text_footer: 'Thank you for your time',
      generic_success_title: '<h2>OPERATION DONE CORRECTLY</h2>',
      generic_success_text: 'The procedure of this form was done correctly.',
      generic_eror_title: '<h2>ERRORS HAVE COURRED</h2>',
      generic_error_text: 'It seems an error has ocurred in the process of this form.',
      pqrs_success_title: '<h2>FORMATO PQRS ENVIADO CORRECTAMENTE</h2>',
      pqrs_success_title: '<h2>PQRS FORMAT SENT CORRECTLY</h2>',
      pqrs_success_text: 'Your request has been sent correctly, at this time our staff will be attentive to your request and will give you a prompt response.',
      pqrs_success_manage_text: 'The PQRS request was created successfully.',
      pqrs_error_title: '<h2>PQRS FORMAT COULD NOT BE SENT </h2>',
      pqrs_error_text: 'Your request could not be processed because the Attachment does not belong to the allowed files. The allowed files are JPEG, PNG Images, and PDF and Word documents. ',
      pqrs_update_title: '<h2>APPLICATION UPDATED SUCCESSFULY </h2>',
      pqrs_update_title_error: '<h2>UPDATE FAILED </h2>',
      pqrs_update_text_extension: 'The time extension of the request has been made, the current expected time will be doubled. If the applicant has authorized a response by email, an email will be sent automatically. ',
      pqrs_update_title_error_extension: '<h2>APPLICATION EXTENSION FAILED</h2>',
      pqrs_update_text_category: 'The request category has been updated successfully, the response time has been updated. ',
      pqrs_update_title_reply: '<h2>RESPONSE PUBLISHED SUCCESSFULLY</h2>',
      pqrs_update_title_reply_error: '<h2>FAILED RESPONSE</h2>',
      pqrs_update_text_reply: 'The request has been answered successfully. If the applicant has authorized a response by email, an email will be sent automatically. ',
      mailbox_success_title: '<h2>MESSAGE SENT CORRECTLY</h2>',
      mailbox_success_text: 'Your message has been sent successfully, at this time our staff will be attentive to your request and will give you a prompt response. ',
      scheduling_success_title: '<h2>SCHEDULED APPOINTMENT</h2>',
      scheduling_success_text: 'The appointment has been correctly scheduled. In a few moments you will receive a confirmation email to the email address provided.',
      scheduling_error_title: '<h2>THE APPOINTMENT COULD NOT BE SCHEDULED </h2>',
      scheduling_error_text: 'The date for which you are requesting is not available. Select a new date.',
      publish_success_title: '<h2>REGISTERED PUBLICATION PROPERLY</h2>',
      publish_success_text: 'The publication has been registered correctly, it can now be seen in the Publications list.',
     
    },
    breadCrums: {
      bc_01: 'Home',
      bc_11: 'Mision & Vision',
      bc_12: 'Curatorship',
      bc_13: 'What we do',
      bc_14: 'Organization chart',
      bc_15: 'Normogram',
      bc_16: 'Curatorship for Kids',
      bc_17: 'Inclusive Curatorship',
      bc_21: 'Publications',
      bc_31: 'File',
      bc_32: 'Check Status',
      bc_33: 'Old Publications',
      bc_41: 'File PQRS',
      bc_42: 'FAQ',
      bc_43: "Curator's School",
      bc_44: 'Contact us',
      bc_45: 'Data and Figures',
      bc_51: 'Liquidator',
      bc_61: 'Scheduling',
      bc_u1: 'Control Panel',
      bc_u2: 'Manage PQRS',
      bc_u3: 'Manage Publications',
      bc_u4: 'Seals',
      bc_u5: 'Appointments',
      bc_u6: 'Message Mailbox',
      bc_u7: 'Management of Requests',
      bc_u8: 'Documents Ocupational Health',
      bc_u9: 'Nomenclatures',
      bc_u10: 'Submitting',
      bc_u11: 'Expenses Calculator',
      bc_u12: 'Archive',
      bc_u13: 'Ids Dictionary',
    },
    misc: {
      btn_chat: 'WhatsApp Chat',
    },
    globals: {
      form_type_id: PQRS_TYPE_ID_ARRAY_EN,
    }
}
// Curatorship
import MISION_T from './curatorship/mision.translation'
import PQRS_T from './transparency/pqrs.translation'
import MAILOX_T from './transparency/mailbox.translation'
import LIQUIDATOR_T from './liquidator/liquidator.translation'
import SCHEDULING_T from './scheduling/scheduling.translation'
import { PQRS_TYPE_ID_ARRAY } from '../../components/vars.global'

export const TRANSLATIONS_ES = {
    app_name: "curaduría",
    title: {
      str_title_1: "ARQ. LUIS CARLOS PARRA SALAZAR",
      str_title_2: "Curaduría Urbana N°1 de Bucaramanga",
    },
    nav_bar: {
      str_item_1: "Inicio",
      str_item_2: "Curaduría",
      str_item_2_1: "Misión y Visión",
      str_item_2_2: "La curaduría",
      str_item_2_3: "Lo que hacemos",
      str_item_2_4: "Organigrama",
      str_item_2_5: "Curaduría para niños",
      str_item_2_6: "Curaduría inclusiva",
      str_item_2_7: "Certificación en línea",
      str_item_3: "Publicaciones",
      str_item_3_1: "Publicaciones",
      str_item_3_2: "Repositorio",
      str_item_3_3: "Nomenclatura",
      str_item_3_4: "Normagrama",
      str_item_3_5: "Noticias",
      str_item_4: "Recursos y asistencia",
      str_item_4_1: "Instrumentos de apoyo",
      str_item_4_2: "Consultar Resultados",
      str_item_4_3: "Ver Estado",
      str_item_5: "Contacto",
      str_item_5_1: "PQRS Y CONTACTO",
      str_item_5_6: "PQRS",
      str_item_5_2: "Preguntas frecuentes",
      str_item_5_3: "Escuela del curador",
      str_item_5_4: "contáctenos",
      str_item_5_5: "Cifras",
      str_item_6: "Liquidador",
      str_item_7: "Agendamiento",
    },
    footer: {
      str_title: "Información General de Contacto",
      str_cr: "© 2022 Curaduría Urbana No.1 de Bucaramanga. Desarrollado por",
      str_item_1: "Calle 36 # 31-39 Centro Empresarial Chicamocha - Local 101 y Local 102",
      str_item_2: "(607) 680 3596",
      str_item_3: "(+57) 316 279 5010",
      str_item_4: "curaduriaurbana1@gmail.com",
      str_item_5: "Lunes a Viernes: 07:00 a.m - 12:30 p.m y 1:00 p.m - 4:00 p.m",
      str_p_1: "La Curaduría Urbana No.1 de Bucaramanga cuenta con el servicio de interpretación de lengua de señas Colombiana.",
      str_p_2: "¿Necesitas ayuda? Comunícate con nosotros por WhatsApp",
      str_btn: "Chat en línea",
    },
    login: {
      str_title: "Inicio de sesión curaduría 1 de Bucaramanga",
      str_user: "Dirección de correo",
      str_id: "Cédula o número de documento identificador",
      str_id2: "Consecutivo de certificación",
      str_pass: "Contraseña",
      str_cert: "Opción",
      str_btn: "Acceder",
      str_btn2: "Generar certificación",
      str_btn4: "Verificar certificación",
      str_btn3: "Enviar",
    },
    curatorship: {
      mision: MISION_T,
    },
    transparency: {
      pqrs_form: PQRS_T,
      mailbox: MAILOX_T,
    },
    liquidator: {
      liquidator: LIQUIDATOR_T,
    },
    scheduling: {
      scheduling: SCHEDULING_T,
    },
    swa_messages: {
      title_wait: '<h2>Procesando Formulario</h2>',
      text_wait: 'Espere un momento por favor, este proceso puede tardar un minuto.',
      text_btn: 'CONTINUAR',
      text_success: 'Formulario procesado con éxito',
      text_error: 'Se han presentado errores en el procesamiento del formulario, inténtelo nuevamente más tarde.',
      text_footer: 'Gracias por su atencion',
      generic_success_title: '<h2>ACCION REALIZADA CON EXITO</h2>',
      generic_success_text: 'Esta operacion fue ralizada correctamente.',
      generic_eror_title: '<h2>SE HAN PRESENTADO ERRORES</h2>',
      generic_error_text: 'Al parece hubo errores en el procesamiento de esta accion.',
      pqrs_success_title: '<h2>FORMATO PQRS ENVIADO CORRECTAMENTE</h2>',
      pqrs_success_text: 'Su solicitud ha sido enviado correctamente, en este momento nuestro personal estará atentos a su solicitud y le dará pronta respuesta',
      pqrs_success_manage_text: 'La solicitud PQRS se creó de forma correcta.',
      pqrs_error_title: '<h2>FORMATO PQRS NO PUDO SER ENVIADO</h2>',
      pqrs_error_text: 'Su solicitud no pudo ser procesada porque el archivo Adjunto no pertenece a los archivos permitidos. Los archivos permitidos son Imágenes JPEG, PNG, y Documentos PDF y Word.',
      pqrs_update_title: '<h2>SOLICITUD ACTUALIZADA CON ÉXITO</h2>',
      pqrs_update_title_error: '<h2>ACTUALIZACIÓN FALLIDA</h2>',
      pqrs_update_text_extension: 'La extensión de tiempo de la solicitud se ha realizado, el tiempo previsto actual será duplicado, Si el solicitante ha autorizado respuesta por email, se le enviara un correo automáticamente.',
      pqrs_update_title_error_extension: '<h2>EXTENSIÓN DE SOLICITUD FALLIDA</h2>',
      pqrs_update_text_category: 'La categoría de la solicitud ha sido actualizada correctamente, el tiempo de respuesta ha sido actualizado.',
      pqrs_update_title_reply: '<h2>RESPUESTA PUBLICADA EXITOSAMENTE</h2>',
      pqrs_update_title_reply_error: '<h2>RESPUESTA FALLIDA</h2>',
      pqrs_update_text_reply: 'Se ha dado respuesta de forma exitosa a la solicitud, Si el solicitante ha autorizado respuesta por email, se le enviara un correo automáticamente.',
      mailbox_success_title: '<h2>MENSAJE ENVIADO CORRECTAMENTE</h2>',
      mailbox_success_text: 'Su mensaje ha sido enviado exitosamente, en este momento nuestro personal estará atentos a su solicitud y le dará pronta respuesta.',
      scheduling_success_title: '<h2>CITA AGENDADA</h2>',
      scheduling_success_text: 'La cita ha sido agendada correctamente. En pocos momentos recibirá un correo de confirmación a la dirección de correo electrónica proveída.',
      scheduling_error_title: '<h2>LA CITA NO PUDO SER AGENDADA</h2>',
      scheduling_error_text: 'La fecha la cual está solicitando no se encuentra disponible. Seleccione una nueva fecha.',
      publish_success_title: '<h2>PUBLICACIÓN REGISTRADA CORRECTAMENTE</h2>',
      publish_success_text: 'La publicación ha sido registrada correctamente, ya puede ser vista en el listado de Publicaciones.',
    },
    breadCrums: {
      bc_01: 'Inicio',
      bc_11: 'Mision y Vision',
      bc_12: 'La Curaduria',
      bc_13: 'Lo que hacemos',
      bc_14: 'Organigrama',
      bc_l14: 'Transparencia',
      bc_15: 'Normatividad',
      bc_16: 'Curaduria para Niños',
      bc_17: 'Curaduria Inclusiva',
      bc_21: 'Publicaciones',
      bc_31: 'Radicar',
      bc_32: 'Ver Estado',
      bc_33: 'Repositorio',
      bc_41: 'Radicar PQRS',
      bc_42: 'Preguntas Frecuentes',
      bc_43: 'Escuela del Curador',
      bc_44: 'Contactenos',
      bc_45: 'Cifras',
      bc_51: 'Liquidador',
      bc_61: 'Agendamiento',
      bc_62: 'Noticias',
      bc_u1: 'Panel de control',
      bc_u2: 'Administrar PQRS',
      bc_u3: 'Administrar Publicaciones',
      bc_u4: 'Sellos',
      bc_u5: 'Citas',
      bc_u6: 'Buzon de Mensajes',
      bc_u7: 'Gestion de Solicitudes',
      bc_u8: 'Documentacion Salud Ocupacional',
      bc_u9: 'Nomenclaturas',
      bc_u10: 'Ventanilla única',
      bc_u11: 'Calculadora de Expensas',
      bc_u12: 'Archivo',
      bc_u13: 'Diccionario de Consecutivos',
    },
    misc: {
      btn_chat: 'Chat de WhatsApp',
    },
    globals: {
      form_type_id: PQRS_TYPE_ID_ARRAY,
    }
}